
<!--Main container-->
<div class ="layout-box">
   
<!--Navbar-->

<!-- Hide the navbar content when the screen is smaller than 767 pixels, hide hamburger menu when screen is bigger than 767 pixels-->
<style>
  @media (max-width: 767px) {
    .navbar-content {
      display: none;
    }
  }

  @media (min-width: 767px) {
    app-hamburger-menu {
      display: none;
    }
  }
</style>

<div class = "navbar" [class.sticky] = "sticky" >
  <div class = "navbar-content">
  <a href="#contact" class="contact" routerLink='/contact'>Contact</a>
  <a href="#experience" class="experience" routerLink='/experience'>Experience</a>
  <a href="#projects" class="projects" id="projects" routerLink='/projects'>Projects</a>
  <a href="#home" class="hello">Hello</a>
</div>

<!-- Hamburger Menu-->
<app-hamburger-menu></app-hamburger-menu>
</div>

<!--This is where the page content is injected-->
<div class="page-content">
<router-outlet></router-outlet>
<app-footer></app-footer>
</div>