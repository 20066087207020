<head>

    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Ubuntu:wght@300&display=swap" rel="stylesheet">
</head>

<div class="background" id ='contactpage'>
    <div class ="row">
        <div class = "midlle-content">
           
           <div class = "content-container">
              <div class="build-message-container">
                <div class="build-message">Come Build With Me</div>
                </div>

                <div class ="img-container">
                  <img src= "\assets\sandCastle.png"/>
                </div>

                <div class = "contact-button-container">
                    <a href="mailto:lucywoodman1@gmail.com">
                    <button class = contact-button>Get In Touch</button>
                    </a>
                </div>
            </div>
        </div>
</div>
