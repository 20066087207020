<head>

    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Ubuntu:wght@300&display=swap" rel="stylesheet">
</head>

<div class="home-background">
    <div class ="row">
       <div class ="left-content">
      
       </div>

       <div class = "middle-content">
        <div class = "home-greeting-container">
            <div class="lucy-container">
              <div class="lucy">Lucy Woodman</div>
            </div>
          
            <div class="message-container">
                <div class = "software-engineer">Software <span style="color:#EBBBA6">Developer</span> based in </div>
                <span></span>
                <div class ="sunny-orlando"><span style="color:#EBBBA6">sunny </span>Orlando, Florida</div>
            </div>

            <div class="contact-button-container">
            <a href="mailto:lucywoodman1@gmail.com">
             <button class ="button1">Contact Me</button>
             </a>
            </div>
        </div>
       </div>

        <div class ="right-content">
           
        </div>
    </div>
</div>
