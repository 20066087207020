<head>

    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Ubuntu:wght@300&display=swap" rel="stylesheet">
</head>

<div class="home-background" id ='projectspage'>
    <div class ="row">
  
          <div class = "project-1-container">
            <div class = "project-1" style="width:300px;height:240px;" (click)="projectOneFunction()">
              <img src= "\assets\folderImageDarkPink.png"/>
           </div>
           
           <div class ="password-generator"> Password Generator</div>
          </div>

 
          <div class ="project-2-container">
          <div class = "project-2"style="width:300px;height:240px;" (click)="projectTwoFunction()">
            <img src= "\assets\folderImageDarkPink.png"/>
          </div>
          <div class ="vigenere"> Vigenere Cipher</div>
          </div>

         <div class ="project-3-container">
          <div class = "project-3"style="width:300px;height:240px;" (click)="projectThreeFunction()">
             <img src= "\assets\folderImageDarkPink.png"/>
          </div>
          <div class ="project-3-text">This Website!</div>
          </div>
    </div>
</div>