<head>

    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Ubuntu:wght@300&display=swap" rel="stylesheet">
</head>

<div class="background" id ='experiencepage'>
    <div class ="row">
           <div class = "greeting-container">
              <div class="job-container">
                <p>Information Technology Specialist at 
                  <br><span style="color:#EBBBA6">Synpatic Sparks Inc.</span><br></p>
                </div>
              
              <div class = "date">Dec 2018 - Present</div>
              <div class="message-container">
                 <ul>
                   <li>Integrated functionality for recording audio in the browser through multi-touch 
                    gestures using Java and JavaScript</li>
                    <li>Improved look and functionality of user interface using HTML, CSS, and Bootstrap </li>
                    <li>Created biometric team-training scenario using Arduino, Unity, and MQTT broker</li>
                    <li>Designed and implemented a new file organization system consisting of 
                      Amazon WorkDocs and Amazon S3</li>
                 </ul>
             </div>
          </div>
    </div>
</div>
