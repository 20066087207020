
<div class = "modal">
<div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">{{ data.title }}
      <button type="button" class="close" aria-label="Close" (click)="actionFunction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
  </div>
  <div class="modal-body">
    <h4 class = "modal-subheader">{{ data.subheader }}</h4>
    <p>{{ data.body }}</p>
  </div>
  </div>